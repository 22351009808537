import * as Styled from './styles';
import { useSelector } from 'react-redux';
import { selectAllTimelineItens } from '../../store/timelineSlice';
import { CarouselBootstrap } from '../../components/CarouselBootstrap';

export const ModalTimeline = () => {
    const data = useSelector(selectAllTimelineItens);
    return (
        <Styled.Container>
            <CarouselBootstrap data={data} />
        </Styled.Container>
    );
};