import * as Styled from './styles';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { AiOutlineInstagram, AiFillYoutube, AiOutlineMail } from 'react-icons/ai';
import { FaLink, FaFacebook, FaLinkedinIn} from 'react-icons/fa';


export const GroupItem = ({ rede, itens }) => {
    const theme = useContext(ThemeContext);
    const socialMedia = () => {
        switch(rede.toLowerCase()){
            case 'whatsapp':
                return  <>
                            <Styled.WhatsappIcon fill={theme.mainColor} size={30}  />
                            
                            { itens.map((item, i) => {
                                return <Styled.Block key={i} style={{ maxWidth: '900px' }} target="_blank"  href={`https://wa.me/${ item.link }`}>
                                    <Styled.Chain>
                                        <FaLink fill={ '#FFF' }/>
                                    </Styled.Chain>
                                    <Styled.MidiaName style={{ textAlign: 'center', marginLeft: '0px' }}>{ item.textoBotao }</Styled.MidiaName>
                                </Styled.Block>
                            }) }
                            
                        </>
            case 'youtube':
                return  <>
                            <AiFillYoutube fill={theme.mainColor} size={40}  />
                                { itens.map((item, i) => {
                                    return <Styled.Block key={i} href={item.link} target="_blank">
                                        <Styled.Chain>
                                            <FaLink fill={ '#FFF' }/>
                                        </Styled.Chain>
                                        <Styled.MidiaName>{ item.textoBotao }</Styled.MidiaName>
                                    </Styled.Block>
                                }) }
                        </>
            case 'linkedin':
                return  <>
                            <FaLinkedinIn fill={theme.mainColor} size={40}  />
                            { itens.map((item, i) => {
                                return <Styled.Block key={i} target="_blank" href={item.link}>
                                    <Styled.Chain>
                                        <FaLink fill={ '#FFF' }/>
                                    </Styled.Chain>
                                    <Styled.MidiaName>{ item.textoBotao }</Styled.MidiaName>
                                </Styled.Block>
                            }) }
                        </>
            case 'facebook':
                return  <>
                            <FaFacebook fill={theme.mainColor} size={40}  />
                            { itens.map((item, i) => {
                                return <Styled.Block key={i} target="_blank" href={item.link}>
                                    <Styled.Chain target="_blank" href={item.link}>
                                        <FaLink fill={ '#FFF' }/>
                                    </Styled.Chain>
                                    <Styled.MidiaName>{ item.textoBotao }</Styled.MidiaName>
                                </Styled.Block>
                            }) }
                        </>
            case 'instagram':
                return  <>
                            <AiOutlineInstagram fill={theme.mainColor} size={40}  />
                            { itens.map((item, i) => {
                                return <Styled.Block key={i} target="_blank" href={item.link}>
                                    <Styled.Chain target="_blank" href={item.link}>
                                        <FaLink fill={ '#FFF' }/>
                                    </Styled.Chain>
                                    <Styled.MidiaName>{ item.textoBotao }</Styled.MidiaName>
                                </Styled.Block>
                            }) }
                        </>
            default: 
                return  <>
                            <AiOutlineInstagram fill={theme.mainColor} size={40}  />
                            { itens.map((item, i) => {
                                return <Styled.Block key={i} target="_blank" href={item.link}>
                                <Styled.Chain target="_blank">
                                    <FaLink fill={'#FFF'}/>
                                </Styled.Chain>
                                <Styled.MidiaName>{ item.textoBotao }</Styled.MidiaName>
                            </Styled.Block>
                            }) }
                        </>
        }
    }
    return (
        <Styled.Container>
            {socialMedia()}
        </Styled.Container>
    );
};