import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
    overflow: hidden;
    position: relative;
    width: 100%;
    ::after{
        padding-top: 56.25%;
        display: block;
        content: '';
    }
    `}
`;
export const Iframe = styled.iframe`
    ${({ theme }) => css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    `}
`