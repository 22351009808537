import * as Styled from './styles';
import { useTheme } from 'styled-components';
import { IconButton } from '../../components/IconButton';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { whatsappHrefString } from '../../services/utils';
export const ModalLinkExterno = () => {
    const theme = useTheme();
    const mock = {
        number: '+55 (00) 00000-0000'
    }

    return (
        <Styled.Container>
            {Array(5).fill(mock).map((contato, i) => <IconButton key={i} text={contato.number} iconComponent={<AiOutlineWhatsApp fill={theme.mainColor} />} link={whatsappHrefString(contato.number)} />)}
        </Styled.Container>
    );
};