
import * as Styled from './styles';
import { useState } from 'react';
import { Iframe } from '../Iframe';
import ModalBootstrapReuse from '../MainModal/ModalBootstrapReuse';
import { ThumbnailButton } from '../ThumbnailButton';

export const VideosContainer = ({ videos }) => {
    const [videoIndex, setVideoIndex] = useState(0);
    const [openClose, setOpenClose] = useState(false);

    const setVideo = (index) => {
        setOpenClose(true);
        setVideoIndex(index)
    }
    return (
        <Styled.Container>
            {videos.map((video, i) => <Styled.VideoBlock  key={i}>
                                        <ThumbnailButton onClickFn={() => setVideo(i)} videoUrl={video.link} />
                                        </Styled.VideoBlock>  )}                
            <ModalBootstrapReuse show={openClose} onHideFn={() => setOpenClose(false)} >
                <Iframe video={videos[videoIndex]} />
            </ModalBootstrapReuse>
        </Styled.Container>
    );
};