import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        justify-content: center;
        padding: 40px;
        gap: 20px;
        min-height: 100px;
    `}
`;


export const VideoBlock = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        box-shadow: none;
        align-items: center;
        width: 326px;
        background: #000;
        span {
            font-family: ${ theme.mainFont }; 
            color: #FFF;
            text-transform: uppercase;
        }
        svg {
            padding: 40px 0;
        }
        button {
            min-height: 242px;
        }
    `}
`;

export const Legenda = styled.div`
    ${({ theme }) => css`
        text-align: center;
        color: #000;
        font-size: 16px;  
        padding-top: 2px;
        color: #000;
        font-family: ${ theme.mainFont }; 
        background: #FFF;
        width: 100%;
        height: 100%;
    `}
`;

export const Thumbnail = styled.button`
        ${({ theme }) => css`
            cursor: pointer;
            background-color: transparent;
            border-color: transparent;
            padding: 0;           
            img {
                width: 100%;
            }
        `}
`;