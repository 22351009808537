import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        overflow-y: auto;
        padding: 42px;
        justify-content: center;
        flex-direction: row;
        overflow-y: auto;
        flex-wrap: wrap;
        @media (max-width:768px){
            padding: 0;
        }
    `}
`;

export const BlockBrasil = styled.div`
    ${({ theme }) => css`
        display: flex;
        width: 100%;
        flex-direction: column;
        box-shadow: unset;
        gap: 15px 15px;
        justify-content: space-between;
        @media (max-width:768px){
            width: 100%;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
        }
    `}
`

export const DivBrasil = styled.div`
    ${({ theme }) => css`
        width: 100%;
        height: fit-content;
        h4 {
            text-align: center;
            font-size: 2rem;
            @media (max-width:768px){
                font-size: 1rem;
                margin-bottom: 15px;
            }
        }
        svg {
            width: 100%;
        }
        @media (max-width:768px){
            width: 100%;
        }
    `}
`