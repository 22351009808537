import * as Styled from './styles';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { selectClickOrigin, selectOrcamentoContent } from '../../../store/modalOrcamentoSlice';

export const Form = ({ setCompletFn }) => {

    const click_origin = useSelector(selectClickOrigin);

    const [ blockFieldset, setBlockFieldset ] = useState(false)
    const { register, handleSubmit, formState: { errors }, setError } = useForm(); 
    const content = useSelector(selectOrcamentoContent);

    const emailCallback = (data) => {
        const [message] = data.split('<script>');
        if(message === 'Mail Sent Successfully'){
            setCompletFn();
        } else {
            setError('mensagem', { type: 'custom', message: 'Ocorreu um erro de conexão'})
        }
    }

    const emailErrorCallback = (error) => {
        setError('mensagem', { type: 'custom', message: 'Ocorreu um erro de conexão'})
    }

    const onSuccess = (data) => {
        setBlockFieldset(true)
        data = {...data, ...window.user_data, click_origin, atendido: false  }
        data.dia = new Date().toLocaleDateString();
        data.hora = new Date().toLocaleTimeString();
        
        window.pushOrcamento(data)
            .then((res) => {
                if(data.estrangeiro === true){
                    data.estado = data.pais;
                }
                console.log(data);
                window.SendEmailAtendimento(data, emailCallback, emailErrorCallback);
                // sendEmail({...data, email: 'raphael.parkinson@gmail.com'})
                //     .then((res) => {
                //         console.log(res)
                //         setCompletFn()
                //     })
                //     .catch((error) => {
                //         console.log(error)
                //         setError('mensagem', { type: 'custom', message: 'Ocorreu um erro de conexão'})
                //     })
                // setCompletFn();
            })
            .catch((error) => {
                console.log(error)
                setError('mensagem', { type: 'custom', message: 'Ocorreu um erro de conexão'})
            })
            .finally(() => {
                setBlockFieldset(false)
            })
    }
    const onError = (error) => {
        console.log(error);
    }

    return (
        <Styled.Container onSubmit={handleSubmit(onSuccess, onError)}>
            <Styled.Fieldset disabled={blockFieldset}>
                <Styled.Label htmlFor={'mensagem'}>{content.message}</Styled.Label>
                <Styled.Textarea {...register('mensagem') } />
                {errors.mensagem && <Styled.Error>{errors.mensagem.message}</Styled.Error>}
                <Styled.SubmitButton>{content.button_text}</Styled.SubmitButton>
            </Styled.Fieldset>
        </Styled.Container>
    );
};