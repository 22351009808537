import * as Styled from './styles';
import { useSelector } from 'react-redux';
import { Video } from '../../components/Video/Video';
import { selectPalavraPresidente } from '../../store/espacoCentenarioSlice';
import { splitYoutubeVideoId } from '../../services/videoFunctions';

export const ModalPalavraPresidente = () => {
    const data = useSelector(selectPalavraPresidente);

    return (
        <Styled.Container>
            {data ? <Video videoId={splitYoutubeVideoId(data[0].link)}/> : null }
        </Styled.Container>
    );
};