import styled from "styled-components"

// Icons
import { AiFillHome } from "react-icons/ai"
import { Sound } from "./Icons"
import { SoundOff } from "./Icons/SoundOff.js"


// ButtonsContainer components
import { MenuHamburger } from "./components"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  gap: 10px;

  height: 45px;
  width: 172px;
  border-radius: 10px;

  background: #140a9a;



  > * {
    cursor: pointer;
    :hover {
      transform: scale(1.05);
    }
  }
`
const MenuButton = styled.button`
  border: none;
  background: unset;
  color: white;
  text-transform: uppercase;
  font-family: var(--font-noto);
  font-weight: bolder;
  user-select: none;
`

export const ButtonsContainer = ({ buttonsFunctionList = {}, menuIsOpen }) => {
  return (
    <Container isOpen={menuIsOpen}>
      <AiFillHome
        color='white'
        size='18px'
        onClick={buttonsFunctionList?.homeButton}
      />
      <img src={"/img/iconSoundOn.png"} className="btSoundOn" id={'btSoundOn'} onClick={buttonsFunctionList?.soundButton} />
      <img src={"/img/iconSoundOff.png"} className="btSoundOff" id={'btSoundOff'} onClick={buttonsFunctionList?.soundButton} />
      <MenuButton onClick={buttonsFunctionList?.menuButton}>Menu</MenuButton>
      <MenuHamburger
        onClick={buttonsFunctionList?.hamburgerButton}
        menuIsOpen={menuIsOpen}
      />
    </Container>
  )
}
