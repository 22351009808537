import * as Styled from './styles';
import { useDispatch, useSelector } from 'react-redux';

import { selectToggle, selectPDFUrl, selectPDFName, setToggle } from '../../store/externalModalSlice';
import { PDFViewer } from '../../components/PDFViewer';
import ModalBootstrapReuse from '../../components/MainModal/ModalBootstrapReuse';


export const ModalExternoPDF = () => {
    const dispatch = useDispatch();
    const toggle = useSelector(selectToggle);
    const pdfUrl = useSelector(selectPDFUrl);
    const pdfName = useSelector(selectPDFName);

    return (
        <ModalBootstrapReuse show={toggle} onHideFn={() => dispatch(setToggle(false))}>
            {pdfUrl ? <PDFViewer fileName={pdfName} pdf={pdfUrl}/> : null }
        </ModalBootstrapReuse>
    );
};