import styled, { css } from 'styled-components';
import { FaWhatsapp } from 'react-icons/fa';

export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-grow: 1;
        background-image: linear-gradient(to bottom, rgba(0,0,0,.3), #493fa712);
        width: 100%;
        height: 68px;
        border-radius: 6px;
        > svg {
            margin: 14px 14px;
            @media (max-width:550px){
                width: 100%;
            }
            @media (max-width:768px){
            }
        }
        @media (max-width:550px){
            height: unset;
            justify-content: center;
            flex-wrap:wrap;
        }  
    `}
`;

export const Block = styled.a`
    ${({ theme }) => css`
    box-shadow: 0px 0px 20px -5px #707070;
    border-radius: 10px;
    display: flex;
    cursor: pointer;
    color: #919191;
    font-size: 16px;
    background-color: #fff;
    flex-basis: 56%;
    align-items: center;
    font-weight: bold;
    height: fit-content;
    margin-top: 8px;
    margin-right: 20px;
    :hover {
        color: inherit;
        text-decoration: none;
    }
    @media (max-width:550px){
        margin-top: 0;
        margin-right:0;
        margin-bottom: 8px;
    }`}
`;

export const Chain = styled.div`
    ${({ theme }) => css`
        background-color: ${({ theme })=> theme.mainColor };
        border-radius: 10px;
        padding: 15px; 
    `}
`;

export const WhatsappIcon = styled(FaWhatsapp)`
    ${({ theme }) => css`
        margin: auto 30px;
    `}
`
export const MidiaName = styled.span`
    ${({ theme }) => css`
        width: 100%;
        margin-left: 10px;    
        margin-right: 10px;
        font-family: ${({ theme }) => theme.mainFont };
        @media (max-width:768px){
            font-size: 10px;
        }
    `}
`

