import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        overflow-y: auto;
        padding: 70px 0px;
    `}
`;

export const ImageBlock = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        box-shadow: 0px 0px 20px -5px #707070;
        border-radius: 10px;
    `}
    
`