import * as Styled from './styles';
import { useSelector } from 'react-redux';
import { selectTextModalContent } from '../../store/modalSlice';
import Interweave from 'interweave';
export const ModalTexto = () => {
    const data = useSelector(selectTextModalContent);
    return (
        <Styled.Container>
            <Interweave content={`<h2>${data.title}</h2><p>${data.content}</p>`} />
        </Styled.Container>
    );
};