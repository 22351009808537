import { useState} from "react";
import * as Styled from "./styles";
import { selectMaquinasData } from "../../store/maquinasSlice";
import { useSelector } from "react-redux";
import { ImageCarousel } from "../../components/ImageCarousel";
import { AiOutlineFileImage } from "react-icons/ai";
import ModalBootstrapReuse from "../../components/MainModal/ModalBootstrapReuse";
export const ModalMaquinasImageGallery = () => {
  const data = useSelector(selectMaquinasData);
  const [toggle, setToggle] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (obj) => {
    setSelectedImage(obj);
    setToggle((prev) => !prev);
  };

  return (
    <Styled.Container>
      <ImageCarousel>
        {data.map((obj, i) => (
          <Styled.CarouselBlock key={i} onClick={() => openModal(obj)}>
            <AiOutlineFileImage size={50} fill={"#E9E9E9"} />
            <h6>{obj.nome}</h6>
          </Styled.CarouselBlock>
        ))}
      </ImageCarousel>

      <ModalBootstrapReuse open={toggle} onHide={() => setToggle(false)}>
        {/* {selectedImage ? (
          <>
            <h2>{selectedImage.nome}</h2>
            <Styled.CarouselBlock>
              <img
                src={`https://dashboard.komatsushowroom.com.br/img/foto/${selectedImage.cabecote}`}
              />
            </Styled.CarouselBlock>
          </>
        ) : null} */}
      </ModalBootstrapReuse>
    </Styled.Container>
  );
};
