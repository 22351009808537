import { createSelector } from '@reduxjs/toolkit';

export const selectVideoDataSelected = createSelector((state) => {
    const { video_data_name } = state.modalVideo;
    switch(video_data_name){
        case 'palavra_presidente':
            return state.espaco_centenario.palavra_do_presidente;
        case 'filmes_centenario':
            return state.espaco_centenario.filmes_centenarios;
        case 'maquinas':
            const { selectedReferencia, tipo, ref } = state.maquinas;
            console.log(ref)
            const arrMapped = state.maquinas.maquinas
                .filter((maquina) => maquina.referencia === ref )
                .map((maquina) => maquina.videos )
                .reduce((acc, value) => {
                    value.forEach((item) => acc.push(item))
                    return acc;
                },[])
                ;
            return arrMapped;
        default: 
            return []
    }
}, (state) => { 
    return state 
})