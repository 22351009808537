import { useSelector } from 'react-redux';
import { removeNonNumeric } from '../../../services/utils';
import { selectEstado } from '../../../store/distribuidoresSlice';
import { DistribuidorDados } from './DistribuidorDados';
import { DistribuidorLinks } from './DistribuidorLinks';
import * as Styled from './styles';
export const ItemDistribuidor = ({ bairro, cep, cidade, email, endereco, nome, razao_social, site, telefone }) => {
    const estado = useSelector(selectEstado);
    return (
        <Styled.Container>
            <DistribuidorLinks tel={removeNonNumeric(telefone)} email={email} />
            <DistribuidorDados estado={estado} 
                bairro={bairro} cep={cep} 
                cidade={cidade} email={email} 
                endereco={endereco} nome={nome} site={site} tel={telefone}/>
        </Styled.Container>
    );
};