import { useEffect, useState } from "react";
import {
  getVimeoVideoThumbnail,
  checkVideoSource,
  splitVimeoVideoId,
  splitYoutubeVideoId,
} from "../../services/videoFunctions";
import * as Styled from "./styles";
export const ThumbnailButton = ({ onClickFn, videoUrl }) => {

    const [src, setSrc] = useState(null);
    const [id, setId] = useState(null);

    useEffect(() => {
        switch (checkVideoSource(videoUrl)) {
            case "youtube":
                setId(splitYoutubeVideoId(videoUrl));
                break;
            case "vimeo":
                setId(splitVimeoVideoId(videoUrl));
                break;
            default:
                break;
        }
    }, [videoUrl]);

    useEffect(() => {
        switch (checkVideoSource(videoUrl)) {
            case "youtube":
                setSrc(`https://img.youtube.com/vi/${id}/0.jpg`);
                break;
            case "vimeo":
                getVimeoVideoThumbnail(id)
                    .then((res) =>    setSrc(res))
                    .catch((error) => {
                        console.warn(error)
                        setSrc("https://via.placeholder.com/220")
                    } );

                break;
            default:
                break;
        }
    }, [videoUrl, id]);

    return (
        <Styled.Container onClick={onClickFn}>
            {src ? <img src={src} /> : null }
        </Styled.Container>
    );
};
