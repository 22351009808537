import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        overflow-y: auto;
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
    `}
`;