import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    itens:[]
};

const timelineSlice = createSlice({
    name: 'timeline',
    initialState,
    reducers: {
        setAllItems:(state, action) => {
            state.itens = action.payload
        },
    }
});

export const { setAllItems } = timelineSlice.actions;
export const selectAllTimelineItens = (state) => state.timeline.itens;

export default timelineSlice.reducer;