import styled, { css } from 'styled-components';
import { AiFillFilePdf } from 'react-icons/ai';

export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        padding: 7vh 0;
        justify-content: center;
        overflow-y: auto;
        @media screen and (max-width: 1200px){
            flex-direction: column;
        }  
    `}
`;
export const Block = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        overflow-y: auto;
        /* flex-grow: 1; */
        flex-basis: 40%;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        width: 100%;
        :hover {
            text-decoration: none;
        }
        h5 {
            color: #919191;
        } 
        a svg {
            width: 35px;
            height: auto;
        } 

        a div svg {
            width: 25px;
            height: auto;
        } 
        
        a img {
            width: 35px;
            height: auto;
        }
    `}
`;

export const IconPdf = styled(AiFillFilePdf)`
    ${({ theme }) => css`
        size: 50;
        fill: ${({ theme }) => theme.mainColor};
    `}
`

export const IconWorld = styled.img`
    ${({ theme }) => css``}
`