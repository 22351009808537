import * as Styled from './styles';
import { useState } from 'react';
import { Carousel, Container, Row, Col } from 'react-bootstrap';

export const CarouselBootstrap = ({ data }) => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    }
    return (
        <Styled.Container>
            <Carousel 
                activeIndex={index} 
                onSelect={handleSelect} 
                nextIcon={<Styled.NextArrow size={50} />}
                nextLabel={''}
                prevIcon={<Styled.PrevArrow size={50} />}
                prevLabel={''}
                >
                {data.map((item, i) =>  {
                return <Carousel.Item key={i}>
                        <Container>
                            <Row className="row-30 justify-content-center">
                                <Col xs={12}>
                                    <h3>{item.ano}</h3>
                                    <p>{item.titulo}</p>
                                </Col>
                                <Col xs={12}>
                                    <Styled.Image
                                        src={`https://dashboard.komatsushowroom.com.br/img/foto/${item.arquivo}`}
                                        alt={item.titulo} />
                                </Col>
                            </Row>
                        </Container>
                    </Carousel.Item>
                } )}
            </Carousel>
            
        </Styled.Container>
    );
};