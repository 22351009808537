import { useEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import { useSelector, useDispatch } from 'react-redux';
import { selectModalExternoToggle, selectModalExternoUrl, setToggle } from '../../store/videoModalExternoSlice';
import { AiOutlineClose } from "react-icons/ai";

export const ModalVideoExterno = () => {
    const dispatch = useDispatch();
    const show = useSelector(selectModalExternoToggle);
    const url = useSelector(selectModalExternoUrl);
    const closeModal = () => {
        dispatch(setToggle(false))
    }

    const [modalDialogWidth, setModalDialogWidth] = useState('0px')

    useEffect(() => {
        var aspectRatio = window.innerWidth / window.innerHeight;
        var maxWidth = window.innerWidth - 20;
        var maxHeight = window.innerHeight - 110;

        if(aspectRatio > 1){
            setModalDialogWidth(`${maxHeight * 16 / 9 }px`);
        } else {
            setModalDialogWidth(`${maxWidth}px`);
        }
    },[]);

    return (
        <Styled.Container 
        show={show}  
        // fullscreen={true}
        // size={ "xl"}
        onHide={closeModal}
        modaldialogwidth={modalDialogWidth}
        >
            <Styled.Header>
                <AiOutlineClose size={30} fill={'#FFF'} onClick={closeModal} style={{ cursor: 'pointer' }}/>
            </Styled.Header>
            <Styled.IframeWrapper>
                <Styled.Iframe 
                src={url}  
                allowscriptaccess="always" allow="autoplay" />
            </Styled.IframeWrapper>
        </Styled.Container>
    );
};
