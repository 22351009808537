
import { useEffect } from 'react';
import { Modal, Button} from 'react-bootstrap';
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectSpecialSize } from './../../store/modalSlice';


// max-width: 1280px;
// max-width: 1200px;
const TheModal = styled(Modal)`
    overflow-y: initial !important;
    z-index: 15000;    
    .modal-body {
        padding: 0;
        
    }
    .modal-dialog{
        .modal-content {
            border-radius: 24px;
        }
    }
    
`
const Body = styled(Modal.Body)`
    height: 100%;
    max-height: 80vh;
    overflow-y:auto;
`
const Header = styled(Modal.Header)`
    background-color: ${({ theme }) => theme.mainColor };
    border-radius: 24px 24px 0 0; 
    color: #FFF;
`;


const ModalBootstrap = ({ show, onHideFn,  children }) => {
    const specialSize = useSelector(selectSpecialSize);
    return <TheModal 
        centered
        show={show}
        // fullscreen={'xl-down'}
        fullscreen={true}
        size={specialSize}
        onHide={onHideFn}
    >
    <Header  onHide={onHideFn} >
        <Modal.Title></Modal.Title>
        <AiOutlineClose size={30} fill={'#FFF'} onClick={onHideFn} style={{ cursor: 'pointer' }}/>
    </Header>
    <Body>
        {children}
    </Body>
  
  </TheModal>
}
export default ModalBootstrap;