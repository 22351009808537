import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tab_open: true,
    click_origin: '',
    content: {
        orcamentos:'',
        button_text: "",
        confirmation_text: "",
        message: "",
        text: "",
        title: "",
        title_confirmation: ""
    }
};

const modalOrcamentoSlice = createSlice({
    name: 'modal_orcamento',
    initialState,
    reducers: {
        setClickOrigin:(state, action) => {
            state.click_origin = action.payload;
        },
        resetClickOrigin:(state) => {
            state.click_origin = '';
        },
        setContent:(state, action) => {
            state.content = action.payload
        },
        setOpen:(state, action) => {
            state.open = action.payload;
        }
    }
});

export const { setClickOrigin, resetClickOrigin, setContent, setOpen } = modalOrcamentoSlice.actions;

export const selectClickOrigin = state => state.modal_orcamento.click_origin
export const selectOrcamentoContent = state => state.modal_orcamento.content;
export const selectOpenTabOrcamento = state => state.modal_orcamento.tab_open; 

export default modalOrcamentoSlice.reducer;