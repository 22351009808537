import * as Styled from './styles';
import { useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { selectDistribuidores, selectScroll } from "../../../store/distribuidoresSlice";
// import ItemDistribuidor from '../ItemDistribuidor/ItemDistribuidor';
import { ItemDistribuidor } from '../ItemDistribuidor/index';

export const SelectedState = () => {
    const blockRef = useRef(null);
    const distribuidores = useSelector(selectDistribuidores);
    const scroll = useSelector(selectScroll);

    useEffect(() => {
        blockRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
    },[scroll]);

    return (
        <Styled.Container>
            <Styled.Block ref={blockRef}>
                {distribuidores.map((distribuidor, i) => <ItemDistribuidor key={i} {...distribuidor} /> )}
            </Styled.Block>      
        </Styled.Container>
    );
};