import * as Styled from './styles';
import Carousel from 'react-elastic-carousel';
import { Arrow } from '../Arrow';

export const ImageCarousel = ({ children }) => {
    
    const breakpoints = [
        { width:1, itemsToShow: 1 },
        { width:550, itemsToShow: 3 },
        { width:768, itemsToShow: 4 },
        { width:1200, itemsToShow: 5 }
    ];

    return (
        <Styled.Container>
            <Carousel breakPoints={breakpoints}  pagination={false} renderArrow={Arrow}>
                {children}
            </Carousel>
        </Styled.Container>
    );
};