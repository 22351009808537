import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    download_materiais__site_centenario: [],
    filmes_centenarios: [],
    model_number_one_em_exposicao_3d:[],
    palavra_do_presidente: []
};

const espacoCentenarioSlice = createSlice({
    name: 'espaco_centenario',
    initialState,
    reducers: {
        setDownloadMateriais: (state, action) => {
            state.download_materiais__site_centenario = action.payload
        },
        setFilmesCentenario: (state, action) => {
            state.filmes_centenarios = action.payload
        },
        setExposicao3D: (state, action) => {
            state.model_number_one_em_exposicao_3d = action.payload
        },
        setPalavraPresidente: (state, action) => {
            state.palavra_do_presidente = action.payload
        }
    }
});

export const setECData = (data) => (dispatch, getState) => {
    dispatch(setDownloadMateriais(data.download_materiais__site_centenario))
    dispatch(setFilmesCentenario(data.filmes_centenarios))
    dispatch(setExposicao3D(data.model_number_one_em_exposicao_3d))
    dispatch(setPalavraPresidente(data.palavra_do_presidente))
}

export const { setDownloadMateriais, setFilmesCentenario, setExposicao3D, setPalavraPresidente } = espacoCentenarioSlice.actions;

export const selectPalavraPresidente = state => state.espaco_centenario.palavra_do_presidente;
export const selectDownloadMateriais = state => state.espaco_centenario.download_materiais__site_centenario;
export const selectFilmesCentenario = state => state.espaco_centenario.filmes_centenarios;
export const selectModalOne = state => state.espaco_centenario.model_number_one_em_exposicao_3d;
export default espacoCentenarioSlice.reducer;