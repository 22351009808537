import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        overflow-y: auto;
        flex-wrap: nowrap;
        justify-content: center;
        padding: 38px;
        gap: 38px;
        height: 100%;
        @media screen and (max-width: 768px){
            flex-direction: column;
        }
    `}
`;

export const TextContainer = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-basis: 48%;
        font-family: ${({ theme }) =>  theme.mainFont};
        h5 {
            font-size: 1.25em;
            margin: 0;
        }
        @media (max-width: 768px){
            flex-basis: 100%;
        }
    `}
`

export const ImageContainer = styled.div`
    ${({ theme }) => css`
        flex-basis: 48%;
        box-shadow: unset;
        flex-direction: column;
        gap: 12px;
        display: flex;
        justify-content: space-between;
        border-radius: 10px;
        img {
            width: 100%;
        }
    `}
`