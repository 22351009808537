import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  :root{
    --font-noto: 'Noto Sans JP', sans-serif;
  }
  body{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    min-width: 100vw;
    min-height: 100vh;
    background: gray;
    
  }
  .centralizer{
    width: 95vw;
    max-width: 1280px;
    margin: 0 auto;
  }
`
