import * as Styled from './styles';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { selectContatoViaWhatsappData } from '../../store/komatsuShopSlice';
import { IconButton } from '../../components/IconButton';
export const ModalWhatsapp = () => {
    const theme = useTheme();
    const data = useSelector(selectContatoViaWhatsappData);

    return (
        <Styled.Container>
            {data.map((obj, i) => <IconButton key={i} iconComponent={<AiOutlineWhatsApp fill={theme.mainFont} />} text={obj.titulo} link={obj.link} /> )}
        </Styled.Container>
    );
};