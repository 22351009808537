import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        flex-wrap: wrap;
        overflow-y: scroll;
        overflow-y: unset;
    width: 100%;
    ul {
        display: flex;
        flex-direction: column;        
        gap: 10px;
        margin:0;
        padding-left: 0;
        width: 100%;
    }
    @media (max-width:768px){
        width: 100%;
    }
    `}
`;

export const Block = styled.div`
    ${({ theme }) => css`
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    `}
`;