import styled, { css } from 'styled-components';
export const Container = styled.button`
    ${({ theme }) => css`
        color: ${theme.mainColor};
        background-color: transparent;
        border-color: transparent;
        svg {
            overflow: hidden;
            vertical-align: middle;
        }
    `}
`;