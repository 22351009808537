import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
    padding: 30px;
    h4{
        margin-bottom: 30px;
        padding
    }
    `}
`;
export const List = styled.ul`
    ${({ theme }) => css`
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;    
    @media (max-width: 768px) {
        padding-left: 0px;
    }
    `}
`;