import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
    maquinas:[],
    ref: 'gd655',
    tipo: 'Máquina',
    cabecote:''
}

const maquinasSlice = createSlice({
    name: 'maquinas',
    initialState,
    reducers: {
        setMaquinasData:(state, action) => {
            state.maquinas = action.payload;
        },
        setMaquinaReferencia:(state, action) => {
            state.ref = action.payload;
        },
        setMaquinaTipo: (state, action) => {
            state.tipo = action.payload;
        },
        setCabecote:(state, action) => {
            state.cabecote = action.payload;
        }
    }
})

export const { setMaquinasData, setMaquinaReferencia, setMaquinaTipo, setCabecote } = maquinasSlice.actions;

export const selectMaquinasData = state => state.maquinas.maquinas;
export const selectMaquinasTipo = state => state.maquinas.tipo;
export const selectedMaquinaReferencia = state => state.maquinas.ref;
export const selectedMaquinasCabecote = state => state.maquinas.cabecote;

export const selectMaquinaFromCabecote = createSelector(
    selectMaquinasData,
    selectedMaquinasCabecote,
    (maquinas, cabecote) => maquinas.find((maquina) => {
            if(maquina.referencia === cabecote){
                return maquina
            }
        })
)

export default maquinasSlice.reducer;