import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    toggle: false,
    pdfUrl: '',
    pdfName: ''
}

const externalModalSlice = createSlice({
    name: 'external_modal',
    initialState,
    reducers: {
        toggle:(state) => {
            state.toggle = !state.toggle;
        },
        setToggle:(state, action) => {
            state.toggle = action.payload
        },
        setPDFUrl:(state, action) => {
            state.pdfUrl = action.payload
        },
        setPDFName:(state, action) => {
            state.pdfName = action.payload
        }
    }
});

export const { toggle, setToggle, setPDFUrl, setPDFName } = externalModalSlice.actions;

export const setAndOpenExternalModal = (pdfUrl, pdfName ) => (dispatch, getState) => {
    dispatch(setToggle(true));
    dispatch(setPDFUrl(pdfUrl))
    dispatch(setPDFName(pdfName))
}

export const selectToggle = (state) => state.external_modal.toggle;
export const selectPDFUrl = (state) => state.external_modal.pdfUrl;
export const selectPDFName = (state) => state.external_modal.pdfName;

export default externalModalSlice.reducer;