import axios from 'axios';
export const getYoutubeVideoName = (id) => axios.get(`https://noembed.com/embed?url=https://www.youtube.com/watch?v=${id}`);
export const getVimeoVideoData = (id) => axios.get(`https://noembed.com/embed?url=https://player.vimeo.com/video/${id}`)
export const getVimeoVideoThumbnail = (id) => axios.get(`https://noembed.com/embed?url=https://player.vimeo.com/video/${id}`).then((res) => res.data.thumbnail_url).catch( error => console.warn(error))
export const splitYoutubeVideoId = (url, pattern='embed/') => url.split(pattern)[1];
export const splitVimeoVideoId = (url, pattern='video/') => url.split(pattern)[1];

export const checkVideoSource = (url) => {
    const check = ['youtube', 'vimeo'];
    let result;
    check.forEach((element) => {
        if(url.includes(element)){
            result = element
        };
    })
    return result;
}