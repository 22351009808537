import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        overflow-y: auto;
        flex-wrap: nowrap;
        gap: 32px;
        padding: 30px;
        @media (max-width: 768px){
            flex-direction: column;
        }
    `}
`;

export const Texts = styled.div`
    ${({ theme }) => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;  
    `}
`;

export const Whatsapps = styled.div`
    ${({ theme }) => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-radius: 10px;  
      gap: 20px 0;
    `}
`;