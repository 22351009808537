import styled, { css } from 'styled-components';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

export const Container = styled.div`
    ${({ theme }) => css`
        padding: 10px;
        .carousel-indicators {
            gap: 3px;
            }
        .carousel-indicators button {
                background-color: ${({ theme }) => theme.mainColor };
                border: 0px solid transparent;
                width: 30px;
                height: 3px;
                opacity: .5;
                transition: opacity .6s ease;
            }
        }
    `}
`;

export const ImageBlock = styled.div`
    ${({ theme }) => css`
        display: flex;
        justify-content: center;
        height: 45vh;
    `}
`;
export const Image = styled.img`
    ${({ theme }) => css`
        /* height: 100%; */
        width: 100%;
    `}
`;

export const NextArrow = styled(MdKeyboardArrowRight)`
    ${({ theme, size = 50}) => css`
        fill: ${theme.mainColor};
        size: 50;
    `}
`
export const PrevArrow = styled(MdKeyboardArrowLeft)`
    ${({ theme}) => css`
        fill: ${theme.mainColor};
    `}
`