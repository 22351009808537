import { useState } from "react"
import styled from "styled-components"
import { useDispatch } from 'react-redux';
import { openMainModal } from './../../store/modalSlice';
// Menu components
import { ButtonsContainer, NavMenu } from "./components"

const MenuContainer = styled.section`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1500;
  box-sizing: border-box;
`

const MoveContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 10px;
  box-sizing: border-box;
`
export const Menu = () => {
  const dispatch = useDispatch();
  const [menuIsOpen, setMenuIsOpen] = useState(true)

  const closeMenu = () => {
    window.initsound();
    setMenuIsOpen(true);
  }

  const buttons = {
    buttonsContainer: {
      homeButton: () => {
        window.NavigateTo('home');
      },
      soundButton: () => {
        window.ToggleSound();
      },
      menuButton: () => {
        setMenuIsOpen((currentStatus) => !currentStatus);
      },
      hamburgerButton: () => {
        setMenuIsOpen((currentStatus) => !currentStatus);
      },
    },
    navMenu: [
      {
        name: "Espaço Centenário",
        redirectUrl: "#",
        func: () => {
          closeMenu();
          window.NavigateTo("centenario");
          window.registerPinCount("espacocentenario");
          // dispatch(openMainModal('centenario'))
        },
      },
      {
        name: "Produtos / Máquinas",
        redirectUrl: "#",
        func: () => {
          closeMenu();
          window.NavigateTo("maquinas");
          // dispatch(openMainModal(''))
        },
      }, 
      {
        name: "Soluções Dantotsu",
        redirectUrl: "#",
        func: () => {
          closeMenu();
          window.NavigateTo("servicosdantosu");
          window.registerPinCount("servicos");
        },
      },  
      {
        name: "Auditório ",
        redirectUrl: "#",
        func: () => {
          closeMenu();
          window.NavigateTo("auditorio");
          window.registerPinCount("auditorio");
        },
      }, 
      {
        name: "Komatsu Shop",
        redirectUrl: "#",
        func: () => {
          closeMenu();
          window.NavigateTo("loja");
          window.registerPinCount("shop");
        },
      },  
      {
        name: "Sala de Reunião",
        redirectUrl: "#",
        func: () => {
          closeMenu();
          window.NavigateTo("reuniao");
          window.registerPinCount("reuniao");
        },
      },
      {
        name: "Tour pelo Showroom",
        redirectUrl: "#",
        func: () => {
          window.registerPinCount("tour");
          window.OpenVideoPlayer('https://player.vimeo.com/video/704240336');
          closeMenu();
        },
      },
      {
        name: "Visite a fábrica",
        redirectUrl: "#",
        func: () => {
          closeMenu();
          window.NavigateTo("fabrica_exterior");
        }
      }
    ]
  }

  return (
    <MenuContainer>
      <MoveContainer className='centralizer' isOpen={menuIsOpen}>
        <ButtonsContainer
          menuIsOpen={menuIsOpen}
          buttonsFunctionList={buttons.buttonsContainer}
        />
        <NavMenu
          menuIsOpen={menuIsOpen}
          buttonsFunctionList={buttons.navMenu}
        />
      </MoveContainer>
    </MenuContainer>
  )
}
