import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sala_reuniao: []
}

const salaReuniaoSlice = createSlice({
    name: 'sala_reuniao',
    initialState,
    reducers: {
        setSalaReuniaoDados: (state, action) => {
            state.sala_reuniao = action.payload
        }
    }
});

export const { setSalaReuniaoDados } = salaReuniaoSlice.actions;
export const selectSalaReuniaoDados = (state) => state.sala_reuniao.sala_reuniao;

export default salaReuniaoSlice.reducer;