import { createSlice } from '@reduxjs/toolkit';
import { setMaquinaTipo, setMaquinaReferencia } from './maquinasSlice';
import { setVideoDataName } from './videoModalSlice';
const initialState = {
    toggle: false,
    url: ''
}
const videoModalExternoSlice = createSlice({
    name: 'modal_video_externo',
    initialState,
    reducers: {
        toggleModal:(state) => {
            state.toggle = !state.toggle;
        },
        setToggle:(state, action) => {
            state.toggle = action.payload;
        },
        setUrl:(state, action) => {
            state.url = action.payload;
        }
    }
});

export const { toggleModal, setToggle, setUrl } = videoModalExternoSlice.actions;

export const setUrlAndOpen = (url) => (dispatch) => {
    dispatch(setUrl(url))
    dispatch(setToggle(true))
}

const videoModalNames = ['palavra_presidente', 'filmes_centenario', 'maquinas'];

export const setMaquinaVideoAndOpen = (name, tipo="Máquina", ref="gd655") => (dispatch, getState) => {
    if(videoModalNames.includes(name) === true ){
        const { maquinas: { maquinas }} = getState();
        const maquina = maquinas.find(maquina => maquina.referencia === ref );
        dispatch(setVideoDataName(name));
        dispatch(setMaquinaTipo(tipo));
        dispatch(setMaquinaReferencia(ref));

        // Refatorar se necessitar de galeria de videos

        dispatch(setUrl(maquina.videos[0].link));
        dispatch(setToggle(true));
    }
}

export const setVideoAndOpenVideoPresidente = () => (dispatch, getState) => {
    const { espaco_centenario: { palavra_do_presidente } } = getState();
    dispatch(setUrl(palavra_do_presidente[0].link));
    dispatch(setToggle(true));
}

export const setCVTPDFandOpenModal = (ref) => (dispatch, getState) => {
    const state = getState();
    const item = state.creating_value_together.creating_value_together.find((item) => item.referencia === ref);
    
    dispatch(setUrl(`https://dashboard.komatsushowroom.com.br/img/arquivo/${item.arquivo}`));
    dispatch(setToggle(true))
}

export const selectModalExternoToggle = state => state.modal_video_externo.toggle;
export const selectModalExternoUrl = state => state.modal_video_externo.url;

export default videoModalExternoSlice.reducer;