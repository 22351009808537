import styled, { ThemeContext } from 'styled-components';
import React, { useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from "react-simple-maps";
import { selectEstado, selectDistribuidores, setDistribuidoresEstado, triggerScroll } from "../../store/distribuidoresSlice";
import malhaBrasil from './malhas/malhaBrasil.json';

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const StyledMarker = styled(Marker)`
  outline: unset !important;
`

const MapaKomatsu = () => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const selectedEstado = useSelector(selectEstado);
  const distribuidores = useSelector(selectDistribuidores);

  const setEstado = (data) => {
    const { codarea } = data.properties;
    dispatch(setDistribuidoresEstado(parseInt(codarea)));
    dispatch(triggerScroll());
  }
  const getDistribuidorColor = (categoria) => {
    switch(categoria){
      case 'Distribuidor':
        return '#FFC82F'
      case 'Komatsu Forest':
        return '140A9A';
      case 'Komatsu no Brasil':
        return '#FFF';
      case 'Aluguel de Novas e Seminovas':
        return '#000';
    }

  }
  return (
    <ComposableMap
      
      // height="800"
      height={800}
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [55, 15, 0],
        scale: 1120
      }}
    >
      <Geographies geography={malhaBrasil}>
        {({ geographies }) => geographies
            .map((geo,i) => {
               return (
              <Geography
                key={i}
                geography={geo}
                fill={parseInt(geo.properties.codarea) !== selectedEstado ? theme.mainColor : 'green'}
                stroke="#D6D6DA"
                onClick={() => setEstado(geo)}
              />
              )
            }
          )
        }
      </Geographies>
      {distribuidores.map(({  latitude, longitude, categoria }, i) => <StyledMarker key={i} coordinates={[parseFloat(longitude), parseFloat(latitude)]} >
      <g
            fill={getDistribuidorColor(categoria)}
            stroke={getDistribuidorColor(categoria)}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            // transform="translate(-12, -24)"
          >
            <circle cx="0" cy="0" r="3" />
            
          </g>
      </StyledMarker>)}
    </ComposableMap>
  );
};

export default MapaKomatsu;
