import { createSlice } from '@reduxjs/toolkit';
import { setMaquinaTipo, setMaquinaReferencia } from './maquinasSlice';
import { openMainModal } from './modalSlice';

const initialState = {
    video_data_name: ''
}

const videoModalSlice = createSlice({
    name: 'modal_video',
    initialState,
    reducers: {
        setVideoDataName:(state, action) => {
            state.video_data_name = action.payload
        }
    }
});

const videoModalNames = ['palavra_presidente', 'filmes_centenario', 'maquinas']

export const { setVideoDataName } = videoModalSlice.actions;

export const setVideoName = (name, tipo="Máquina", ref="gd655" ) => (dispatch, getState) => {
    if(videoModalNames.includes(name) === true ){
        dispatch(setVideoDataName(name));
        dispatch(setMaquinaTipo(tipo));
        dispatch(setMaquinaReferencia(ref));
        dispatch(openMainModal('modal_videos'));
    }
}

export const selectVideoData = state => state.modal_video.video_data_name;
export default videoModalSlice.reducer;