import * as Styled from './styles';
export const Iframe = ({ video }) => {
    return (
        <Styled.Container>
            <Styled.Iframe
            src={video} 
            style={{position: 'absolute', top: 0, left: 0}}
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen />
        </Styled.Container>
    );
};