import { useSelector } from 'react-redux';
import { selectMaquinasData } from '../../store/maquinasSlice';
import * as Styled from './styles';
export const ModalMaquina = () => {
    const data = useSelector(selectMaquinasData);    
    const reduceByTipo = (acc, value) => {
        if(acc[value.tipo] === undefined){
            acc[value.tipo] = [value]
        }
        acc[value.tipo].push(value)
        return acc;
    }
    return (
        <Styled.Container>
            {data.map((maquina, i) => <Styled.Image key={i} src={`https://dashboard.komatsushowroom.com.br/img/foto/${maquina.cabecote}`}/> )}
        </Styled.Container>
    );
};