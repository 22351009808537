import styled, { css } from 'styled-components';
export const Container = styled.button`
    ${({ theme }) => css`
        cursor: pointer;
        background-color: transparent;
        border-color: transparent;
        padding: 0;
        img {
            width: 100%;
        }
    `}
`;