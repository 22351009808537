import styled, { css } from 'styled-components';
export const Container = styled.button`
    ${({ theme, rounded, background }) => css`
        bottom: 30px;
        background-color: ${ (background !== '') ? background : "#FFF"};
        border-color: transparent;
        cursor: pointer;
        padding: 10px 24px;
        border-radius: ${rounded ? '30px' : '0px'} ;
        color: #707070;
        font-family: Noto Sans;
        font-size: 22px;
        font-weight: bold;
    `}
`;