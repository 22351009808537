import * as Styled from './styles';
import { consts }  from 'react-elastic-carousel';
import { IoArrowBackCircleSharp, IoArrowForwardCircleSharp } from 'react-icons/io5';

export const Arrow = ({ type , onClick, isEdge }) => {
    const pointer = (type === consts.PREV) ? <IoArrowBackCircleSharp size={25} /> : <IoArrowForwardCircleSharp size={25}/> ;
    return (
        <Styled.Container onClick={onClick} disabled={isEdge}>
            {pointer}
        </Styled.Container>
    );
};