import styled, { css } from 'styled-components';
export const Container = styled.form`
    ${({ theme }) => css``}
`;

export const Fieldset = styled.fieldset`
    ${({ theme }) => css`
        display: flex;
        flex-direction: column;
        gap: 10px;
    `}
`;

export const Label = styled.label`
    ${({ theme }) => css`
        margin: 0;
        font-size: 16px;
    `}
`;
export const Textarea = styled.textarea.attrs({ rows: 4 })`
    ${({ theme }) => css`
        resize: none;
        font-size: 16px;
    `}
`;
export const SubmitButton = styled.button.attrs({ type: 'submit'})`
    ${({ theme }) => css`
        text-transform: uppercase;
        color: #fff;
        border: 1px solid transparent;
        background-color: ${theme.mainColor};
        width: fit-content;
        align-self: center;
        border-radius: 5px;
        padding: 5px 20px;
        font-weight: 700;
    `}
`;
export const Error = styled.span`
    ${({ theme }) => css`
        color: ${theme.secondaryColor};
    `}
`;