import styled, { css } from 'styled-components';
import { FaListAlt } from 'react-icons/fa';
import { Button } from '../Button';
import { AiFillFilePdf } from 'react-icons/ai';

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        flex-direction: column;
        flex-basis: 40%;
        justify-content: space-between;
        box-shadow: 0px 0px 20px -5px #707070;
        border-radius: 10px;
        align-items: center;
        padding: 45px 25px 16px 25px;
        margin: 20px 0;
        @media (max-width:768px){
            flex-basis: 46%;
        }
    `}
`;
export const ThumbButton = styled(Button)`
   ${({ theme }) => css`
        /* display: flex; */
        border-radius: 10px;
        @media screen and (max-width:768px){ */
            /* flex-direction: column; */
            /* align-items: center; */
        /* }
   `}
`

export const Image = styled.img`
    ${({ theme }) => css`
        width: 100%;
    `}
`;
export const Span = styled.span`
    text-align: center;
    width: 100%;
    @media (max-width:768px){
            font-size: 0.5rem;
        }
`;

export const Icon = styled(FaListAlt)`
    ${({ theme }) => css`
        margin-right: 10px;
        fill: ${theme.mainColor};
    `}
`