import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        overflow-y: auto;
        flex-wrap: wrap;
        justify-content: space-around;
        gap: 50px;
        padding: 42px;`}
`;


export const PDFButton = styled.button`
    ${({ theme }) => css`
        background-color: white;
        display: flex;
        flex-basis: 15%;
        flex-direction: column;
        justify-content: space-between;
        max-width: 175px;
        padding: 5px;
        box-shadow: 0px 0px 20px -5px #707070;
        border-radius: 10px;
        border-color: transparent;
        cursor: pointer;
        svg{
            fill: ${({ theme })=> theme.mainColor };
        }
    `}
`;
export const Legend = styled.span`
    ${({ theme }) => css`
        font-family: ${theme.mainFont};
        font-size: 16px;
        display: flex;
        justify-content: center;
        padding-bottom: 16px;
        text-align: center;
    `}
`;