import styled, { css } from 'styled-components';
export const Container = styled.div`
    ${({ theme }) => css`
        display: flex;
        overflow-y: auto;
        padding: 70px 30px;
        flex-wrap:wrap;
        img {
            width: 100%;
        }
    `}
`;