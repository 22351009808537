export const siglaToString = (estado) => {
    switch(estado){
        case 'AC':
            return 'acre';
        case 'AL':
            return 'alagoas';
        case 'AP':
            return 'amapá';
        case 'AM': 
            return 'amazonas';
        case 'BA':
            return 'bahia';
        case 'CE':
            return 'ceará';
        case 'DF': 
            return 'distrito federal';
        case 'ES': 
            return 'espírito santo';
        case 'GO':
            return 'goiás';
        case 'MA': 
            return 'maranhão';
        case 'MT': 
            return 'mato grosso';
        case 'MS': 
            return 'mato grosso do sul';
        case 'MG': 
            return 'minas gerais';
        case 'PA': 
            return 'pará';
        case 'PB':
            return 'paraíba';
        case 'PR': 
            return 'paraná'
        case 'PE': 
            return 'pernambuco';
        case 'PI': 
            return 'piauí';
        case 'RR': 
            return 'roraima';
        case 'RO': 
            return 'rondônia';
        case 'RJ': 
            return 'rio de janeiro';
        case 'RN': 
            return 'rio grande do norte';
        case 'RS':
            return 'rio grande do sul';
        case 'SC': 
            return 'santa catarina';
        case 'SP': 
            return 'são paulo';
        case 'SE': 
            return 'sergipe';
        case 'TO': 
            return 'tocantins';
    }
}

export const codareaToString = (codarea) => {
    switch(codarea){
        case 12:
            return 'acre';
        case 27:
            return	'alagoas';
        case  13:
            return 'amazonas';
        case  16:
            return 'amapá';
        case  29:
            return 'bahia';
        case 23:
            return 'ceará';
        case  53:
            return 'distrito federal';
        case 32:
            return 'espírito santo';
        case 52:
            return 'goiás';
        case 21:
            return 'maranhão';
        case 31:
            return 'minas gerais';
        case 50:
            return 'mato grosso do sul';
        case 51:
            return 'mato grosso';
        case 15:
            return 'pará';
        case 25:
            return 'paraíba';
        case 26:
            return 'pernambuco';
        case 22:
            return 'piauí';
        case 41:
            return 'paraná';
        case 33:	
            return 'rio de janeiro';
        case 24:
            return 'rio grande do norte';
        case 11:
            return 'rondônia';
        case 14:
            return 'roraima';
        case 43:
            return 'rio grande do sul';
        case 42:
            return 'santa catarina';
        case 28:
            return 'sergipe';
        case 35:	
            return 'são paulo';
        case 17:
            return 'tocantins';
    }
}

export const codareaToSigla = (codarea) => {
    switch(codarea){
        case 12:
            return 'AC';
        case 27:
            return	'AL';
        case  13:
            return 'AM';
        case  16:
            return 'AP';
        case  29:
            return 'BA';
        case 23:
            return 'CE';
        case  53:
            return 'DF';
        case 32:
            return 'ES';
        case 52:
            return 'GO';
        case 21:
            return 'MA';
        case 31:
            return 'MG';
        case 50:
            return 'MS';
        case 51:
            return 'MT';
        case 15:
            return 'PA';
        case 25:
            return 'PB';
        case 26:
            return 'PE';
        case 22:
            return 'PI';
        case 41:
            return 'PR';
        case 33:	
            return 'RJ';
        case 24:
            return 'RN';
        case 11:
            return 'RO';
        case 14:
            return 'RR';
        case 43:
            return 'RS';
        case 42:
            return 'SC';
        case 28:
            return 'SE';
        case 35:	
            return 'SP';
        case 17:
            return 'TO';
        default:
            return codarea;
    }
}