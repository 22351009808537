
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from 'react-icons/ai';
import { BsArrowRightCircle, BsArrowLeftCircle } from 'react-icons/bs';
import { FiDownload } from 'react-icons/fi';
import { MdOutlineDownloadForOffline } from 'react-icons/md';
import * as Styled from './styles';

export const Toolbar = ({slots}) => {
    const {
        CurrentPageLabel,
        CurrentPageInput,
        Download,
        EnterFullScreen,
        GoToNextPage,
        GoToPreviousPage,
        NumberOfPages,
        Print,
        ShowSearchPopover,
        Zoom,
        ZoomIn,
        ZoomOut,
    } = slots;

    return (
        <Styled.Container>
            <Styled.Block>
                <Styled.ToolbarItem>
                    <ZoomOut>
                        {(props) => (
                            <Styled.Button onClick={props.onClick}>
                                <AiOutlineMinusCircle fill={'#000'} size={30}/>
                            </Styled.Button>
                        )}
                    </ZoomOut>
                </Styled.ToolbarItem>
                <Styled.ToolbarItem>
                    <Zoom />
                </Styled.ToolbarItem>
                <Styled.ToolbarItem>
                    <ZoomIn>
                    {(props) => (
                        <Styled.Button onClick={props.onClick}>
                            <AiOutlinePlusCircle fill={'#000'} size={30}/>
                        </Styled.Button>
                    )}
                    </ZoomIn>
                </Styled.ToolbarItem>
            </Styled.Block>
            <Styled.Block>
                <Styled.ToolbarItem>
                    <GoToPreviousPage>
                    {(props) => (
                        <Styled.Button onClick={props.onClick}>
                            <BsArrowLeftCircle fill={'#000'} size={30}/>
                        </Styled.Button>
                    )}
                    </GoToPreviousPage>
                </Styled.ToolbarItem>
                <Styled.ToolbarItem>
                    <CurrentPageLabel />
                </Styled.ToolbarItem>
                <Styled.ToolbarItem>
                    / <NumberOfPages />
                </Styled.ToolbarItem>
                <Styled.ToolbarItem>
                    <GoToNextPage>
                    {(props) => (
                        <Styled.Button onClick={props.onClick}>
                            <BsArrowRightCircle fill={'#000'} size={30}/>
                        </Styled.Button>
                    )}
                    </GoToNextPage>
                </Styled.ToolbarItem>
            </Styled.Block>
            <Styled.Block>
                <Styled.ToolbarItem>
                    <Download>
                    {(props) => (
                        <>
                        <Styled.Button onClick={props.onClick}>
                          <MdOutlineDownloadForOffline fill={'#000'} size={30}/> 
                            Download
                        </Styled.Button>
                        </>
                    )}
                    </Download>
                </Styled.ToolbarItem>
            </Styled.Block>
            </Styled.Container>
    );

}
