import * as Styled from './styles';
import { GroupItem } from './GroupItem';

export const SocialMidiaGroup = ({ data }) => {
    
    const reduceByType = (array) => {
        let result = array.reduce((acumulador, valor) => {
            let typeObject = acumulador.find((item) => {
                return item.rede === valor.rede;
            });

            if(typeObject === undefined){
                acumulador.push({ rede: valor.rede, itens: [ valor ] });   
            } else {
                typeObject.itens.push(valor);
            }
            return acumulador;
        }, []);
        return result;
    }
    
    return (
        <Styled.Container>
            {reduceByType(data).map((item, i) => <GroupItem key={i} {...item} />)}
        </Styled.Container>
    );
};